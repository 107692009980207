import {
  Typography,
  useTheme,
  Grid,
  Paper,
  useMediaQuery,
  IconButton,
  Icon,
} from "@mui/material";
import {
  DatosUsuarioIcon,
  IntegrationIcon,
  PagosIcon,
} from "@/icons/control-acceso";
import Image from "next/image";

export function OurClients() {
  const MuiTheme = useTheme();
  const viewPortMatches = {
    xs: useMediaQuery(MuiTheme.breakpoints.down("xs")),
    sm: useMediaQuery(MuiTheme.breakpoints.down("sm")),
    md: useMediaQuery(MuiTheme.breakpoints.down("md")),
    lg: useMediaQuery(MuiTheme.breakpoints.down("lg")),
  };

  // console.log(viewPortMatches);

  const slides = [
    {
      url: "/static/marea.svg",
    },
    {
      url: "/static/gla.svg",
    },

    {
      url: "/static/rayo.svg",
    },
    {
      url: "/static/angostura.svg",
    },
    {
      url: "/static/cardenales.svg",
    },
    {
      url: "/static/cusica.svg",
    },
  ];
  const pasarelasPagos = [
    {
      url: "/static/zelle.png",
    },
    {
      url: "/static/pago-movil.png",
    },

    {
      url: "/static/paypal.png",
    },
    {
      url: "/static/cripto.png",
    },
    {
      url: "/static/tarjeta.png",
    },
  ];

  // const size = viewPortMatches.md ? 125 : 256;
  const size = 100;

  function computeSlidesPerView() {
    if (viewPortMatches.sm) {
      return 1;
    }

    if (viewPortMatches.md) {
      return 3;
    } else {
      return 5;
    }
  }
  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: "#F5F5F5",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: "0 10%",
          paddingBottom: "80px",
        }}
      >
        <Grid
          item
          flexDirection="column"
          xs={12}
          md={6}
          sx={{
            /*       backgroundColor: MuiTheme.palette.secondary.main, */
            /*  width: "500px",
            height: { sm: "400px", xs: "500px" }, */
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          {" "}
          <Grid
            item
            sm={12}
            sx={{
              height: "80px",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Typography fontSize={"2rem"} fontWeight={700}>
              Nuestros Clientes
            </Typography>
          </Grid>
          <Grid
            item
            container
            height="auto"
            gap={2}
            columnGap={5}
            justifyContent="center"
            alignItems="center"
            sx={{ margin: "0 5%", userSelect: "none" }}
          >
            {" "}
            {slides.map((slide, i) => {
              return (
                <Grid
                  key={i}
                  item
                  sm={3}
                  xs={4}
                  direction="row"
                  sx={{ marginTop: "20px" }}
                >
                  <Image width={90} height={90} alt=" " src={slide.url} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          item
          flexDirection="column"
          xs={12}
          md={6}
          sx={{
            /*       backgroundColor: MuiTheme.palette.secondary.main, */
            /*  width: "500px",
            height: { sm: "400px", xs: "500px" }, */
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          {" "}
          <Grid
            item
            sm={12}
            sx={{
              height: "80px",
              marginBottom: "40px",
            }}
          >
            {" "}
            <Typography fontSize={"2rem"} fontWeight={700}>
              Pasarelas de pago
            </Typography>
          </Grid>
          <Grid
            item
            container
            height="auto"
            gap={2}
            columnGap={5}
            justifyContent="center"
            alignItems="center"
            sx={{ margin: "0 5%", userSelect: "none" }}
          >
            {" "}
            {pasarelasPagos.map((slide, i) => {
              return (
                <Grid
                  key={i}
                  item
                  sm={3}
                  xs={4}
                  direction="row"
                  sx={{ marginTop: "20px" }}
                >
                  <Image width={90} height={90} alt=" " src={slide.url} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
