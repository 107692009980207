import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function Spinner({ loading }) {
  return (
    <Backdrop open={loading} style={{ zIndex: '1' }} >
      <CircularProgress color="error" />
    </Backdrop>
  );
}
