import Head from "next/head";
import { LandingContainer } from "@/containers/LandingContainer";

export default function Home() {
  const meta_data = (
    <>
      <link rel="icon" href="/favicon.ico" />
      <meta
        key="title"
        name="title"
        content="B9 Ticketing | Boletería Digital"
      />
      <meta
        key="description"
        name="description"
        content="B9 Ticketing es tu plataforma para venta de entradas online. Descubre los beneficios de tener tu propio motor de boletería electrónica, gestionando la venta de tus tickets con nuestra solución automanejable."
      />

      <meta key="og:type" property="og:type" content="website" />
      <meta
        key="og:url"
        property="og:url"
        content="https://www.b9ticketing.com/"
      />
      <meta
        key="og:title"
        property="og:title"
        content="B9 Ticketing | Boletería Digital"
      />
      <meta
        key="og:description"
        property="og:description"
        content="B9 Ticketing es tu plataforma para venta de entradas online. Descubre los beneficios de tener tu propio motor de boletería electrónica, gestionando la venta de tus tickets con nuestra solución automanejable."
      />
      <meta
        key="og:image"
        property="og:image"
        content="https://www.b9ticketing.com/b9t-meta-logo.png"
      />

      <meta
        key="twitter:card"
        property="twitter:card"
        content="summary_large_image"
      />
      <meta
        key="twitter:url"
        property="twitter:url"
        content="https://www.b9ticketing.com/"
      />
      <meta
        key="twitter:title"
        property="twitter:title"
        content="B9 Ticketing | Boletería Digital"
      />
      <meta
        key="twitter:description"
        property="twitter:description"
        content="B9 Ticketing es tu plataforma para venta de entradas online. Descubre los beneficios de tener tu propio motor de boletería electrónica, gestionando la venta de tus tickets con nuestra solución automanejable."
      />
      <meta
        key="twitter:image"
        property="twitter:image"
        content="https://www.b9ticketing.com/b9t-meta-logo.jpg"
      />
    </>
  );

  return (
    <>
      <Head>
        <title>B9 Ticketing | Boletería Digital</title>

        {meta_data}
      </Head>
      <main>
        <LandingContainer />
      </main>
    </>
  );
}
