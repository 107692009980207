import { B9IsotipoSvg } from "@/icons/B9IsotipoSvg";
import {
  Box,
  Typography,
  useTheme,
  Grid,
  IconButton,
  Button,
  useMediaQuery,
} from "@mui/material";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from "@/icons/social-icons";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { ContactFormSection } from "@/components/ContactFormSection";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import {
  QrIcon,
  AutenticacionIcon,
  MonitoreoIcon,
  DatosUsuarioIcon,
  IntegrationIcon,
  PagosIcon,
} from "@/icons/control-acceso";

import { OurClients } from "@/components/OurClients";
import Link from "next/link";
import { CardServices } from "@/components/CardServices";
import AddTaskIcon from "@mui/icons-material/AddTask";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import PaidIcon from "@mui/icons-material/Paid";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

export function LandingContainer() {
  const theme = useTheme();
  const hasViewportMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const hasViewportMatchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const copy = {
    feature1: {
      title: "<span b9-nowrap>Ventajas de</span> <br />B9 ticketing",
      // content: "Podrás crear y administrar servicios de eventos, partidos, presentaciones y conciertos, en cualquier tipo de ubicación con o sin sillas numeradas en estadios, teatros salones, cines, gimnasios y más.",
      content:
        "Podrás crear y administrar servicios de eventos, partidos, presentaciones y conciertos, en cualquier tipo de ubicación con o sin sillas numeradas en estadios, teatros salones, cines, gimnasios y más.",
    },
    feature2: {
      title: "Página de <br /><span b9-nowrap>evento propia</span>",
      content:
        "Crea experiencias a tu propio estilo ¡Tus eventos merecen tu marca! Contarás con tu sitio propio, importante para ganar una visibilidad y una tasa de conversión inigualables.",
    },
    feature3: {
      title: "<span b9-nowrap>Plataformas de </span>pago automática",
      content:
        "Tendrás la opción de pagos en línea con pasarelas que prefieras: pago móvil, Zelle, Paypal <br /> cripto monedas",
    },

    feature4: {
      title: "<span b9-nowrap>Control de</span> <br />acceso QR",
      content:
        "Para tener un óptimo manejo y seguridad en los accesos de tu evento, cuentas con nuestra app móvil de escaneo de códigos QR que va integrado en tiempo real a tu sistema de boletería electrónica",
    },
    feature5: {
      title:
        "<span b9-nowrap>Transacciones </span> <span yellow>seguras y eficientes</span>",
    },
  };

  const slides = [
    {
      url: "/static/marea.svg",
    },
    {
      url: "/static/gla.svg",
    },
    {
      url: "/static/rayo.svg",
    },
    {
      url: "/static/angostura.svg",
    },
    {
      url: "/static/cardenales.svg",
    },
    {
      url: "/static/cusica.svg",
    },
    {
      url: "/static/angostura-web.svg",
    },
  ];

  const icons = [
    {
      component: QrIcon,
      text: "Ingreso por escaneo de código QR",
      size: 0.47,
    },
    {
      component: AutenticacionIcon,
      text: "Verificación de identidad de los usuarios",
      size: 0.45,
    },
    {
      component: MonitoreoIcon,
      text: "Monitoreo en tiempo real",
      size: 0.47,
    },
  ];
  const iconsTrasacctions = [
    {
      component: PagosIcon,
      text: "Pagos en línea desde cualquier lugar y momento",
      size: 0.45,
    },
    {
      component: IntegrationIcon,
      text: "Fácil integración en variedad de sistemas",
      size: 0.45,
    },
    {
      component: DatosUsuarioIcon,
      text: "Protege los datos sensibles de los usuarios",
      size: 0.45,
    },
  ];

  const size = 200;

  const EventPlannerPagesSwiper = (
    <>
      <Swiper
        autoplay={{
          // delay: 2500,
          delay: 10000,
        }}
        style={{ height: "inherit" }}
        slidesPerView={1}
        loop={true}
        navigation={{
          nextEl: "#event-page-custom-swiper-button-next",
          prevEl: "#event-page-custom-swiper-button-prev",
        }}
        modules={[Navigation, Autoplay]}
      >
        {slides.map((slide, i) => {
          return (
            <SwiperSlide key={`SwiperSlide-${i}`}>
              {({ isActive }) => {
                return (
                  <Grid
                    container
                    width={size}
                    height="inherit"
                    direction="column"
                    justifyContent="center"
                    sx={{ margin: "0 auto", userSelect: "none" }}
                  >
                    <Image
                      className={
                        isActive
                          ? "filter-swiper-slide-active"
                          : "filter-swiper-slide"
                      }
                      width={size}
                      height={size}
                      alt=" "
                      src={slide.url}
                    />
                  </Grid>
                );
              }}
            </SwiperSlide>
          );
        })}
        {slides.map((slide, i) => {
          return (
            <SwiperSlide key={`SwiperSlide-${i}`}>
              <Grid
                container
                width={size}
                height="inherit"
                direction="column"
                justifyContent="center"
                sx={{ margin: "0 auto", userSelect: "none" }}
              >
                <Image width={size} height={size} alt=" " src={slide.url} />
              </Grid>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <IconButton
      style={{
        display: { sm: "none", md: "block" },
        position: "absolute",
        top: "8rem",
        left: "-20px",
        zIndex: "1001",
      }}
      id="event-page-custom-swiper-button-prev"
    >
      <Image
        width={64}
        height={64}
        alt=" "
        src="/static/left-arrow.svg"
      />
    </IconButton>{" "}
    <IconButton
      style={{
        display: { sm: "none", md: "block" },
        position: "absolute",
        top: "8rem",
        right: "-20px",
        zIndex: "1001",
      }}
      id="event-page-custom-swiper-button-next"
    >
      <Image
        width={64}
        height={64}
        alt=" "
        src="/static/right-arrow.svg"
      />
    </IconButton> */}
    </>
  );

  const ControlAccessSwiper = (
    <>
      <Swiper
        autoplay={{
          delay: 2500,
        }}
        style={{ height: "inherit" }}
        slidesPerView={hasViewportMatch ? 3 : 1}
        loop={true}
        modules={[Navigation, Autoplay]}
      >
        {icons.map((icon, i) => {
          return (
            <SwiperSlide key={`SwiperSlide-icons-${i}`}>
              <Grid
                container
                height="inherit"
                direction="column"
                justifyContent="center"
                sx={{
                  margin: "20px auto",
                  userSelect: "none",
                  flexWrap: "nowrap",
                }}
              >
                <Grid item>
                  <icon.component size={icon.size} />
                </Grid>
                <Grid item>
                  <Typography
                    mt="1rem"
                    fontSize="1.1rem"
                    fontWeight="900"
                    sx={{}}
                    dangerouslySetInnerHTML={{ __html: icon.text }}
                  />
                </Grid>
              </Grid>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
  const TransactionsSwiper = (
    <>
      <Swiper
        autoplay={{
          delay: 2500,
        }}
        style={{ height: "inherit" }}
        slidesPerView={hasViewportMatch ? 3 : 1}
        loop={true}
        modules={[Navigation, Autoplay]}
      >
        {iconsTrasacctions.map((icon, i) => {
          return (
            <SwiperSlide key={`SwiperSlide-icons-${i}`}>
              <Grid
                container
                height="inherit"
                direction="column"
                justifyContent="center"
                sx={{
                  margin: "20px auto",
                  userSelect: "none",
                  flexWrap: "nowrap",
                }}
              >
                <Grid item>
                  <icon.component size={icon.size} />
                </Grid>
                <Grid item>
                  <Typography
                    mt="1rem"
                    fontSize="1.1rem"
                    fontWeight="900"
                    sx={{}}
                    dangerouslySetInnerHTML={{ __html: icon.text }}
                  />
                </Grid>
              </Grid>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );

  return (
    <>
      <section>
        <HeaderSection />
        <HeroSection />
        {/* <CardsServices /> */}

        <Grid
          container
          gap={8}
          justifyContent="center"
          alignItems="center"
          bgcolor="#F5F5F5"
          padding="40px 5%"
        >
          <CardServices
            title={copy.feature1.title}
            content={copy.feature1.content}
            small="Ventajas"
            imageUrl={<AddTaskIcon fontSize="large" />}
          />
          <CardServices
            title={copy.feature2.title}
            content={copy.feature2.content}
            small="Tu evento"
            imageUrl={<LaptopChromebookIcon fontSize="large" />}
          />
          <CardServices
            title={copy.feature3.title}
            content={copy.feature3.content}
            small="Pagos"
            imageUrl={<PaidIcon fontSize="large" />}
          />
          <CardServices
            title={copy.feature4.title}
            content={copy.feature4.content}
            small="Acceso QR"
            imageUrl={<QrCodeScannerIcon fontSize="large" />}
          />
        </Grid>
        {/* <HeroMetricsSection /> */}
        <OurClients />
        {/* <OurClientsCarouselSection /> */}
        {/*        <FeatureHeroCardSection
          pStyles={{
            width: { xs: "320px", sm: "24rem", md: "100%" },
            textAlign: { xs: "center", md: "right" },
            justifyContent: "flex-end",
          }}
          title={copy.feature1.title}
          content={copy.feature1.content}
          direction="ltr"
          textContainerStyles={{
            textAlign: { xs: "center", md: "right" },
            alignItems: { xs: "center", md: "flex-end" },
          }}
          sectionContainerStyles={{
            alignItems: "center",
            flexDirection: "row-reverse",
          }}
          imageContainerStyles={{
            width: {
              xs: "320px",
              lg: "400px",
            },
            height: {
              xs: "320px",
              lg: "400px",
            },
          }}
          imageUrl={"/static/ventajas.png"}
        />
        <FeatureHeroCardSection
          pStyles={{
            width: { xs: "320px", sm: "24rem", md: "100%" },
            textAlign: { xs: "center", md: "left" },
          }}
          title={copy.feature2.title}
          titleStyles={{
            justifyContent: "flex-start",
          }}
          content={copy.feature2.content}
          textContainerStyles={{
            textAlign: { xs: "center", md: "left" },
            alignItems: { xs: "center", md: "flex-start" },
            marginRight: { md: "3rem" },
          }}
          imageSlot={EventPlannerPagesSwiper}
        />
        <FeatureHeroCardSection
          title={copy.feature4.title}
          titleStyles={{
            width: "100%",
          }}
          content={copy.feature4.content}
          pStyles={{
            width: { xs: "320px", sm: "100%" },
            textAlign: "center",
          }}
          textContainerStyles={{
            textAlign: {
              xs: "center",
              // md: "left"
            },
            alignItems: {
              xs: "center",
              // md: "flex-end"
            },
          }}
          sectionContainerStyles={{
            alignItems: "center",
            flexDirection: "column",
          }}
          imageContainerStyles={{
            minWidth: "100%",
            minHeight: "17rem",
            height: "inherit",
            marginTop: "30px",
          }}
          imageSlot={
            <div style={{ height: "inherit", marginTop: "2rem" }}>
              {ControlAccessSwiper}
            </div>
          }
        />
        <FeatureHeroCardSection
          title={copy.feature3.title}
          titleStyles={{
            width: "100%",
          }}
          content={copy.feature3.content}
          pStyles={{
            width: { xs: "320px", sm: "100%" },
            textAlign: "center",
          }}
          textContainerStyles={{
            textAlign: {
              xs: "center",
              // md: "left"
            },
            alignItems: {
              xs: "center",
              // md: "flex-end"
            },
          }}
          sectionContainerStyles={{
            alignItems: "center",
            flexDirection: "column",
          }}
          imageContainerStyles={{
            minWidth: { xs: "100%", md: "600px" },
            minHeight: "15rem",
            height: "inherit",
          }}
          imageUrl={"/static/pagos.svg"}
        /> */}
        {/*         <FeatureHeroCardSection
          title={copy.feature5.title}
          titleStyles={{
            width: "100%",
          }}
          textContainerStyles={{
            textAlign: {
              xs: "center",
              // md: "left"
            },
            alignItems: {
              xs: "center",
              // md: "flex-end"
            },
          }}
          sectionContainerStyles={{
            alignItems: "center",
            flexDirection: "column",
          }}
          imageContainerStyles={{
            minWidth: "100%",
            minHeight: "20rem",
            height: "inherit",
          }}
          imageSlot={TransactionsSwiper}
        /> */}
        <ContactFormSection />
        <FooterSection />
      </section>
    </>
  );
}

function HeaderSection() {
  const MuiTheme = useTheme();

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: MuiTheme.palette.primary.main,
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "0.1rem",
          height: "60px",
          padding: "0 5%",
        }}
      >
        <Grid
          container
          style={{
            maxWidth: MuiTheme.breakpoints.values.xl,
          }}
        >
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <B9IsotipoSvg />
            </Grid>
            <Typography
              color="white"
              fontWeight="900"
              variant="h3"
              component="h2"
              fontSize={{
                xs: "2rem",
              }}
              sx={{
                display: { xs: "flex", sm: "none" },
              }}
            >
              TICKETING
            </Typography>

            {/* <Grid
              xs={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            > */}
            <Grid justifyContent="flex-end">
              <Typography
                position="right"
                color="white"
                fontWeight="900"
                variant="h3"
                component="h2"
                fontSize={{
                  xs: "1rem",
                }}
                sx={{
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <Link href="https://www.back9.com.ve/">¿Quienes somos?</Link>
              </Typography>
            </Grid>
            {/*  </Grid> */}
          </Grid>
          {/* Burger Menu */}
          {/* <Grid item>
          <IconButton>
            <MenuIcon sx={{ color: "white", width: "3rem", height: "3rem" }} />
          </IconButton>
        </Grid> */}
        </Grid>
      </Grid>
    </>
  );
}

function HeroSection() {
  const MuiTheme = useTheme();
  const hasViewportMatch = useMediaQuery(MuiTheme.breakpoints.up("sm"));

  const viewPortMatches = {
    xs: useMediaQuery(MuiTheme.breakpoints.down("sm")),
    sm: useMediaQuery(MuiTheme.breakpoints.down("md")),
    md: useMediaQuery(MuiTheme.breakpoints.down("lg")),
    lg: useMediaQuery(MuiTheme.breakpoints.down("xl")),
  };

  return (
    <>
      {" "}
      <Grid
        container
        sx={{
          backgroundColor: MuiTheme.palette.primary.main,
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Grid
          container
          className="hero-img-background"
          sx={{
            padding: "1rem",
            alignItems: "center",
            textAlign: {
              xs: "center",
              sm: "left",
            },
            justifyContent: "center",
            minHeight: "45vh",
            paddingBottom: "2rem",
            position: "relative",
            maxWidth: MuiTheme.breakpoints.values.xl,
          }}
        >
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            // xs={12}
            sm
            sx={{
              zIndex: 1,
            }}
          >
            <Grid item mb="1rem">
              <Typography
                component="h1"
                textAlign="center"
                fontSize={{
                  xs: "2rem",
                  sm: "2rem",
                }}
                fontWeight="800"
                color="white"
                sx={{
                  mb: { xs: "1rem" },
                  lineHeight: { xs: "60px", sm: "40px" },
                  marginTop: "1.5rem",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    "<span b9-nowrap>Tú lo sueñas</span> <br /><span b9-nowrap>nosotros hacemos</span> tu <span yellow b9-nowrap>ticketing</span>",
                }}
              />{" "}
            </Grid>
            <Grid item mb="1rem">
              {hasViewportMatch && (
                <Typography
                  color="#ECA71E"
                  textAlign="center"
                  sx={{ width: "25rem" }}
                >
                  Vende entradas y gestiona tus asistentes sin esfuerzo con
                  nuestra solución fácil de manejar.
                </Typography>
              )}{" "}
            </Grid>
            {/*             <Grid item mb="1rem">
              <Button
                id="hero-button"
                onClick={() =>
                  document.querySelector("#contactanos").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                  })
                }
                sx={{
                  marginTop: {
                    sm: "2rem",
                    md: "1rem",
                  },
                  width: "15rem",
                  borderRadius: 100,
                  backgroundColor: MuiTheme.palette.secondary.main,
                  fontWeight: "900",
                  "&:hover": {
                    backgroundColor: MuiTheme.palette.secondary.main,
                  },
                  zIndex: 1,
                }}
              >
                Crea tu evento
              </Button>
            </Grid> */}
          </Grid>
          {/* <Grid
            item
            container
            xs={12}
            sm
            sx={{ position: "relative", justifyContent: "center" }}
          >
            <Grid item>
              <Button
                id="hero-button"
                onClick={() =>
                  document.querySelector("#contactanos").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                  })
                }
                sx={{
                  marginTop: {
                    sm: "2rem",
                    md: "auto",
                  },
                  width: "12rem",
                  backgroundColor: MuiTheme.palette.secondary.main,
                  fontWeight: "900",
                  "&:hover": {
                    backgroundColor: MuiTheme.palette.secondary.main,
                  },
                  zIndex: 1,
                }}
              >
                Crear Evento
              </Button>
            </Grid>
            <Box
            sx={{
              position: "absolute",
              top: -140,
              left: -60,
              zIndex: 0,
              filter: "opacity(0.2)",
            }}
          >
            <Image alt=" " src="/box.png" width="320" height="320" />
          </Box>
          </Grid> */}
        </Grid>
      </Grid>
    </>
  );
}

function FooterSection() {
  const MuiTheme = useTheme();

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: "#F5F5F5",
          borderTop: `1rem solid ${MuiTheme.palette.secondary.main}`,
          alignItems: "center",
          justifyContent: "space-evenly",
          textAlign: "center",
          backgroundColor: MuiTheme.palette.primary.main,
          minHeight: {
            xs: "30rem",
            sm: "auto",
            md: "7rem",
          },
        }}
      >
        <Grid item xs={12} sm>
          <Box
            m={{
              xs: "0.5rem",
              sm: "0.5rem",
            }}
          >
            <B9IsotipoSvg size={2} />
          </Box>
        </Grid>
        <Grid item xs={12} sm whiteSpace="nowrap">
          <Typography
            variant="h6"
            fontWeight="600"
            color={MuiTheme.palette.secondary.main}
          >
            Únete a nosotros
          </Typography>
          <br />
          <a
            href="https://www.facebook.com/back9ve?mibextid=LQQJ4d"
            target="_blank"
            rel="noreferrer"
          >
            <IconButton>
              <FacebookIcon />
            </IconButton>
          </a>
          <a
            href="https://twitter.com/back9ve?s=21&t=NkiPYiZRCFNshXtLLSEHpA"
            target="_blank"
            rel="noreferrer"
          >
            <IconButton>
              <TwitterIcon />
            </IconButton>
          </a>
          <a
            href="https://instagram.com/b9ticketing?igshid=MjkzY2Y1YTY="
            target="_blank"
            rel="noreferrer"
          >
            <IconButton>
              <InstagramIcon />
            </IconButton>
          </a>
          <a
            href="https://www.linkedin.com/company/back9ve/"
            target="_blank"
            rel="noreferrer"
          >
            <IconButton>
              <LinkedInIcon />
            </IconButton>
          </a>
        </Grid>
        <Grid item xs={12} sm my="3rem">
          <Typography
            whiteSpace="nowrap"
            fontSize="0.8rem"
            fontWeight="600"
            color="white"
          >
            <KeyboardArrowRightIcon
              sx={{ position: "relative", top: "6px", left: "-4px" }}
              htmlColor={MuiTheme.palette.secondary.main}
            />
            Términos y condiciones
          </Typography>
          <Typography
            whiteSpace="nowrap"
            fontSize="0.8rem"
            fontWeight="600"
            color="white"
          >
            <KeyboardArrowRightIcon
              sx={{ position: "relative", top: "6px", left: "-4px" }}
              htmlColor={MuiTheme.palette.secondary.main}
            />
            Políticas de privacidad
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: "10px" }}>
          <Typography component="span" color="white" fontSize="0.8rem">
            2023 B9 ticketing.
          </Typography>{" "}
          <Typography
            component="span"
            color="white"
            fontSize="0.8rem"
            sx={{ whiteSpace: "nowrap" }}
          >
            Todos los derechos reservados
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
