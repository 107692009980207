import React from "react";
import { Grid, Typography } from "@mui/material";
import style from "../styles/card.module.css";

export function CardServices({ title, content, imageUrl, small }) {
  return (
    <Grid item className={style.card}>
      <Grid className={style.content}>
        <Grid className={style.back}>
          <Grid
            style={{ display: "flex", flexDirection: "column" }}
            className={style.backContent}
          >
            {imageUrl}
            {/*   <img
              src={imageUrl}
              style={{ width: "150px" }}
              alt=""
              loading="lazy"
            /> */}
            <Typography
              textAlign="center"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Grid>
        </Grid>
        <Grid className={style.front}>
          <Grid className={style.img}>
            <Grid className={style.circle}></Grid>
            <Grid className={style.circle} id={style.right}></Grid>
            <Grid className={style.circle} id={style.bottom}></Grid>
          </Grid>

          <Grid className={style.frontContent}>
            {/* <Typography
              fontSize="0.7rem"
              className={style.badge}
              dangerouslySetInnerHTML={{ __html: small }}
            ></Typography> */}
            <Grid className={style.description}>
              <Grid className={style.title}>
                <Typography
                  fontSize="0.8rem"
                  textAlign="center"
                  className={style.title}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
