import { CloseOutlined } from "@mui/icons-material";
import {
  Typography,
  useTheme,
  Grid,
  Button,
  TextField,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import Spinner from "./Spinner";
import { useState } from "react";
import Image from "next/image";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export function ContactFormSection() {
  const MuiTheme = useTheme();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const sendEmail = async (data, resetForm) => {
    try {
      /* setLoading(true);
      const res = await fetch("/api/send-email", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) await Promise.reject(res); */
      setSendSuccess(true);
      resetForm();
    } catch (error) {
      console.log({ error });
      enqueueSnackbar(error.message ?? "A ocurrido un error inesperado.", {
        preventDuplicate: true,
        autoHideDuration: 10000,
        variant: "error",
        action: (key) => {
          return (
            <IconButton onClick={() => closeSnackbar(key)}>
              <CloseOutlined />
            </IconButton>
          );
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      empresa: "",
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: yup.object().shape({
      empresa: yup.string().required("Campo requerido."),
      name: yup.string().required("Campo requerido."),
      email: yup.string().required("Campo requerido."),
      phone: yup.string().required("Campo requerido."),
      message: yup.string().required("Campo requerido."),
    }),
    onSubmit: ({ empresa, name, email, phone, message }) => {
      sendEmail(
        {
          empresa,
          name,
          email,
          phone,
          message,
        },
        resetForm
      );
    },
  });

  return (
    <>
      <Grid container sx={{ padding: "0 5%", backgroundColor: "#F5F5F5" }}>
        <Spinner loading={loading} />
        <Grid
          item
          md={6}
          sx={{
            display: { md: "flex", sm: "none", xs: "none" },
            width: "auto",
            height: "480px",
            margin: "4rem auto",
          }}
        >
          <img
            className={classes.images}
            style={{ borderRadius: "20px 0 0 20px" }}
            src="/gallery/galery1.jpg"
            alt="back9 Ticketing"
          />
          <img
            className={classes.images}
            src="/gallery/galery2.jpg"
            alt="back9 Ticketing"
          />
          <img
            className={classes.images}
            src="/gallery/galery3.jpg"
            alt="back9 Ticketing"
          />
          <img
            className={classes.images}
            src="/gallery/galery4.jpg"
            alt="back9 Ticketing"
          />
          <img
            className={classes.images}
            src="/gallery/galery5.jpg"
            alt="back9 Ticketing"
          />
        </Grid>

        <Grid
          item
          md={6}
          sm={12}
          id="contactanos"
          container
          pt="2rem"
          sx={{
            backgroundColor: "#FECC1D",
            alignItems: "center",
            justifyContent: "center",
            margin: "4rem auto",
            textAlign: "center",
            backgroundColor: MuiTheme.palette.secondary.main,
            borderRadius: { md: "0 20px 20px 0", sm: "20px", xs: "20px" },
            minHeight: "20rem",
            maxWidth: MuiTheme.breakpoints.values.md,
          }}
        >
          <Grid item mb="1rem" xs={12} md={4}>
            <Typography
              width={{ xs: "12rem", sm: "auto" }}
              fontWeight="600"
              fontSize="1.2rem"
              variant="h4"
              textAlign={{
                md: "left",
              }}
              mx="auto"
            >
              <Typography
                component="span"
                width={{ xs: "12rem", sm: "auto" }}
                fontWeight="600"
                fontSize="1.2rem"
                variant="h4"
                textAlign={{
                  lg: "left",
                }}
                color="white"
              >
                ¿Tienes una <br />
                pregunta?
                <br />
              </Typography>
              <br /> Inicia tu evento <br /> con nosotros
            </Typography>
          </Grid>
          <Grid
            container
            component="form"
            onSubmit={handleSubmit}
            xs={12}
            md={6}
            style={{ maxWidth: "32rem" }}
            sx={{
              alignItems: "center",
              justifyContent: {
                xs: "center",
                sm: "space-between",
              },
              textAlign: "center",
            }}
          >
            <Grid item mb="1rem" width="12rem !important" xs={12} md={12} sm>
              <TextField
                label="Empresa"
                name="empresa"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.empresa}
                error={touched.empresa && Boolean(errors.empresa)}
                helperText={touched.empresa && errors.empresa}
                InputProps={{ disableUnderline: true }}
                variant="outlined"
                sx={{
                  width: {
                    xs: "14rem ",
                    sm: "100%",
                  },
                  backgroundColor: "white",
                  borderRadius: "0.7rem",
                  "& .MuiOutlinedInput-input": {
                    // padding: "12px",
                    padding: "7px",
                  },
                  "& .MuiInputLabel-root": {
                    fontWeight: "600",
                    fontSize: "0.8rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    fontWeight: "600",
                    borderRadius: "0.7rem",
                  },
                  "& .MuiOutlinedInput-root": {
                    fontWeight: "600",
                  },
                  "& .MuiFormHelperText-root": {
                    fontSize: "0.5rem",
                  },
                }}
              />
            </Grid>
            <Grid item mb="1rem" width="12rem !important" xs={12} sm={12}>
              <TextField
                label="Nombre Completo"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                InputProps={{ disableUnderline: true }}
                variant="outlined"
                sx={{
                  width: {
                    xs: "14rem ",
                    sm: "100%",
                  },
                  backgroundColor: "white",
                  borderRadius: "0.7rem",
                  "& .MuiOutlinedInput-input": {
                    // padding: "12px",
                    padding: "7px",
                  },
                  "& .MuiInputLabel-root": {
                    fontWeight: "600",
                    fontSize: "0.8rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    fontWeight: "600",
                    borderRadius: "0.7rem",
                  },
                  "& .MuiOutlinedInput-root": {
                    fontWeight: "600",
                  },
                  "& .MuiFormHelperText-root": {
                    fontSize: "0.5rem",
                  },
                }}
              />
            </Grid>
            <Grid item mb="1rem" width="12rem !important" xs={12} sm={12}>
              <TextField
                label="Correo electrónico"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                InputProps={{ disableUnderline: true }}
                variant="outlined"
                sx={{
                  width: {
                    xs: "14rem ",
                    sm: "100%",
                  },
                  backgroundColor: "white",
                  borderRadius: "0.7rem",
                  "& .MuiOutlinedInput-input": {
                    // padding: "12px",
                    padding: "7px",
                  },
                  "& .MuiInputLabel-root": {
                    fontWeight: "600",
                    fontSize: "0.8rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    fontWeight: "600",
                    borderRadius: "0.7rem",
                  },
                  "& .MuiOutlinedInput-root": {
                    fontWeight: "600",
                  },
                  "& .MuiFormHelperText-root": {
                    fontSize: "0.5rem",
                  },
                }}
              />
            </Grid>
            <Grid item mb="1rem" width="12rem !important" xs={12} sm={12}>
              <TextField
                label="Teléfono"
                name="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
                InputProps={{ disableUnderline: true }}
                variant="outlined"
                sx={{
                  width: {
                    xs: "14rem ",
                    sm: "100%",
                  },
                  backgroundColor: "white",
                  borderRadius: "0.7rem",
                  "& .MuiOutlinedInput-input": {
                    // padding: "12px",
                    padding: "7px",
                  },
                  "& .MuiInputLabel-root": {
                    fontWeight: "600",
                    fontSize: "0.8rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    fontWeight: "600",
                    borderRadius: "0.7rem",
                  },
                  "& .MuiOutlinedInput-root": {
                    fontWeight: "600",
                  },
                  "& .MuiFormHelperText-root": {
                    fontSize: "0.5rem",
                  },
                }}
              />
            </Grid>
            <Grid item mb="1rem" xs={12}>
              <TextField
                label="¿Cómo podemos ayudarte?"
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                error={touched.message && Boolean(errors.message)}
                helperText={touched.message && errors.message}
                InputProps={{ disableUnderline: true }}
                type="textarea"
                multiline
                variant="outlined"
                rows="3"
                sx={{
                  width: {
                    xs: "14rem ",
                    sm: "100%",
                  },
                  backgroundColor: "white",
                  borderRadius: "0.7rem",
                  "& .MuiInputBase-root": {
                    // padding: "12px",
                    padding: "7px",
                  },
                  "& .MuiInputLabel-root": {
                    fontWeight: "600",
                    fontSize: "0.8rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    fontWeight: "600",
                    borderRadius: "0.7rem",
                  },
                  "& .MuiOutlinedInput-input": {
                    fontWeight: "600",
                    // minHeight: "16rem",
                  },
                  "& .MuiFormHelperText-root": {
                    fontSize: "0.5rem",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} mb={{ sm: "1rem", md: 0 }}>
              <Button
                type="submit"
                sx={{
                  width: "10rem",
                  color: "white",
                  backgroundColor: MuiTheme.palette.primary.main,
                  fontWeight: "600",
                  "&:hover": {
                    backgroundColor: MuiTheme.palette.primary.main,
                  },
                  borderRadius: 100,
                }}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal open={sendSuccess} onClose={() => setSendSuccess(false)}>
        <Box sx={style}>
          <Grid
            container
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap="10px"
            p="15px"
          >
            <CheckCircleOutlineIcon fontSize={"large"} color={"success"} />
            <Typography fontSize={"1.3rem"} textAlign={"center"}>
              ¡Su mensaje ha sido enviado con exito!
            </Typography>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
const useStyles = makeStyles((theme) => ({
  images: {
    cursor: "crosshair",
    width: 0,
    flexGrow: 1,
    objectFit: "cover",
    opacity: "0.8",
    transition: "0.5s ease",
    "&:hover": {
      backgroundColor: "#333",
      width: "300px",
      opacity: 1,
      filter: "contrast(120%)",
    },
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};
